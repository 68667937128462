import React, { useEffect, useState } from 'react'
import { services } from '../services/service.js';

function Footer() {
  {/*const [stats, setStats] = useState(null)
  useEffect(() => {
    const fetchStats = async () => {
      const data = await services.getStats();
      setStats(data);
    };

    fetchStats();
  }, []);*/}
  return (
    
    <footer>
    {/*}
      <div id="stats-info">
        {stats && <p>Updated: {new Date(stats.lastUpdate * 1000).toLocaleString()} | Names: {stats.totalNames} | Posts: {stats.totalPosts} | Replies: {stats.totalReplies}</p>}
      </div>
    */}
    </footer>
    
  )
}

export default Footer