import axios from "axios";
import NProgress from 'nprogress'

// Configure NProgress before using it
NProgress.configure({ 
  showSpinner: false,
  trickleSpeed: 100,
  minimum: 0.3,
  parent: 'body' // This ensures NProgress has a valid parent element
})

// Add a custom event for render control
const RENDER_READY_EVENT = 'renderReady'

let activeRequests = 0

// Progress tracking functions
const trackRequest = () => {
  activeRequests++
  if (activeRequests === 1) {
    NProgress.start()
    document.body.classList.remove('render-ready')
  }
}

const trackResponse = () => {
  activeRequests--
  if (activeRequests === 0) {
    NProgress.done()
    document.body.classList.add('render-ready')
    document.dispatchEvent(new Event(RENDER_READY_EVENT))
  }
}

// Create and configure API instances
const createApiWithProgress = (config) => {
  const instance = axios.create(config)

  // Add request interceptor
  instance.interceptors.request.use((config) => {
    trackRequest()
    return config
  })

  // Add response interceptor
  instance.interceptors.response.use(
    (response) => {
      trackResponse()
      return response
    },
    (error) => {
      trackResponse()
      return Promise.reject(error)
    }
  )

  return instance
}

const api_ = createApiWithProgress({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
    },
})

const api_turbo = createApiWithProgress({
    baseURL: process.env.REACT_APP_TURBO_URL,
    headers: {
      'Content-Type': 'application/json',
    },
})

const api_mempool = createApiWithProgress({
    baseURL: process.env.REACT_APP_MEMPOOL_URL,
    headers: {
      'Content-Type': 'application/json',
    },
})

const api_inscribe = createApiWithProgress({
    baseURL: process.env.REACT_APP_INSCRIBE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
})

export const apiData = {api_, api_turbo, api_mempool, api_inscribe}