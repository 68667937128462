import React, { useState, useEffect } from 'react';
import Wrapper from '../components/Wrapper.js';
import { inscriboorService } from '../services/inscriboor.js';
import Wallet from "sats-connect";
import { services } from '../services/service.js';
import { useLocation } from 'react-router-dom';
import NotificationBar from '../components/NotificationBar.js'


function Register() {
  const [username, setUsername] = useState('');
  const [status, setStatus] = useState('');
  const [address, setAddress] = useState('');
  const [feeRate, setFeeRate] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [orderID, setOrderID] = useState(null);
  const [responseStatus, setResponseStatus] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [paymentaddress, setpaymentaddress] = useState('');
  const [countdown, setCountdown] = useState('');
  const [ShowOrderstatus, setShowOrderstatus] = useState(false);
  const location = useLocation();
  


  useEffect(() => {
    // Set status from navigation state if it exists
    if (location.state?.message) {
      setStatus(location.state.message);
    }
    
    inscriboorService.getRecommendedFeeRate(setFeeRate);
    let ordinalAddress = window.localStorage.getItem('ordinalAddress')
    let paymentAddress = window.localStorage.getItem('paymentAddress')
    setAddress(ordinalAddress)
    setpaymentaddress(paymentAddress)
    if (inscriboorService.isValidTaprootAddress(ordinalAddress)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [location.state]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nameParam = params.get('name');
    if (nameParam) {
      setUsername(nameParam);
    }
  }, [location.search]);

  //make sure username is properly formatted
  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    // Validate that the username includes a name and a namespace separated by a period
    const isValidFormat = /^[^\.]+\.[^\.]+$/.test(value);
    if (!isValidFormat) {
      setStatus('Username must include a name and a namespace, separated by a period (e.g., name.namespace)');
      setSubmitDisabled(true);
    } else {
      setStatus('');
      setSubmitDisabled(false);
    }
  };
  const handleAddressInputChange = (e) => {
    const address = e.target.value;
    setAddress(address);
    // if (inscriboorService.isValidTaprootAddress(address)) {
    //   e.target.style.backgroundColor = '#d4edda'; // light green
    //   setSubmitDisabled(false);
    // } else {
    //   e.target.style.backgroundColor = '#f8d7da'; // light red
    //   setSubmitDisabled(true);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setStatus('')
      const checkNameIfExist = await services.checkName(username)
      if (checkNameIfExist && checkNameIfExist.message === "Name exists") {
        setTimeout(() => {
          setStatus('This name already exists')
        }, 500);
      } else {
        const payload = {
          address,
          feeRate,
          data: username,
          mime: "text/plain",
          padding: "550",
          type: "single",
          collection: "bitter",
        };

        /*inscriboorService.inscribe(payload, (response) => {
          setOrderID(response.fundingAddress);
          setResponseStatus(`Please send ${response.sats} sats ($${response.usd}) to ${response.fundingAddress}. You have ${response.timeToInscribe / 60000} minutes.`);
          inscriboorService.startCountdown(response.timeToInscribe, setCountdown);
        });*/
        const inscribeData = await inscriboorService.inscribe(payload)
        if (inscribeData) {
          const response = inscribeData.data
          //setOrderID(response.fundingAddress);
          //setResponseStatus(`Please send ${response.sats} sats ($${response.usd}) to ${response.fundingAddress}. You have ${response.timeToInscribe / 60000} minutes.`);
          //inscriboorService.startCountdown(response.timeToInscribe, setCountdown);

          try {
            const transferesponse = await Wallet.request("sendTransfer", {
              recipients: [
                {
                  address: response.fundingAddress,
                  amount: Number(response.sats)
                },
              ],
            });
            if (transferesponse.status === "success") {
              setStatus('Your name will appear in your wallet once the next block clears')
              try {
                await services.addName({
                  name: username,
                  address: address
                });
              } catch (error) {
                console.error("Error adding name:", error);
              }
              setUsername('')
            } else {
              setStatus(transferesponse.error.message)
            }
          } catch (err) {
            alert(err);
          }
        }
      }
    } finally {
      console.log('lze')
    }
  };

  const handleCheckOrderStatus = async () => {
    if (orderID) {
      const orderStatus = await inscriboorService.checkOrderStatus(orderID)
      if (orderStatus && orderStatus.data) {
        if (orderStatus.data.status.toLowerCase().includes("done")) {
          // setOrderStatus({
          //   status: orderStatus.data.status,
          //   inscriptionID: orderStatus.data.inscriptionIDs[0],
          // });
          setStatus({
            status: orderStatus.data.status,
            inscriptionID: orderStatus.data.inscriptionIDs[0],
          });
        } else {
          // setOrderStatus({
          //   status: orderStatus.data.status,
          // });
          setStatus({
            status: orderStatus.data.status,
          });
        }
      }
      /*, (response) => {
        setResponseStatus(response.status);
      });*/
    }
  };

  return (
    <Wrapper>

      <>
        {status &&
          <NotificationBar message={status} />
        }
        <form id="register" onSubmit={handleSubmit}>
          <label htmlFor="user">bitter name (any SNS name)</label><br />
          <input
            type="text"
            id="user"
            name="user"
            value={username}
            onChange={handleUsernameChange}
            placeholder="username.namespace e.g. hello.bitter"
            required
          />
          {/*
          <label htmlFor="addressInput">ordinal receiving address</label><br />
          <input
            type="text"
            id="addressInput"
            name="address"
            value={address}
            onChange={handleAddressInputChange}
            placeholder="address"
            required
            readOnly
          /><br />
          */}
          <label htmlFor="feeInput">fee rate (sat/vB)</label><br />
          <input
            type="number"
            id="feeInput"
            value={feeRate}
            onChange={(e) => setFeeRate(e.target.value)}
            placeholder="Fee"
          /><br />
          <div className="submit">
            <button 
              type="submit" 
              id="submitButton" 
              disabled={submitDisabled}
            >
              Submit
            </button>
            {/*
            <button
              type="button"
              id="checkOrderStatus"
              onClick={handleCheckOrderStatus}
              className={ShowOrderstatus ? '' : 'hidden'}
            >
              Check Order Status
            </button>
            */}
          </div>
        </form>
      </>

    </Wrapper>
  );
}

export default Register;
