import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../components/Wrapper.js';
import { inscriboorService } from '../services/inscriboor.js';
import { services } from '../services/service.js';
import {encode} from 'cbor2';
import RpcErrorCode from 'sats-connect';
import Wallet from 'sats-connect'
import * as btc from 'micro-btc-signer';
import { hex, base64 } from '@scure/base';
import axios from 'axios';
import { Psbt } from "bitcoinjs-lib";
import Post from './home-post.js';
import NotificationBar from '../components/NotificationBar.js'
/* global BigInt */

const Reply = () => {
  const [postContent, setPostContent] = useState('');
  const [replytoID, setReplytoID] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [feeRate, setFeeRate] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [responseStatus, setResponseStatus] = useState('');
  const [inscriptionID, setInscriptionID] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [replyPost, setReplyPost] = useState(null);
  const [status, setStatus] = useState('');
  const networkConfig = JSON.parse(process.env.REACT_APP_NETWORK_CONFIG);
  

  useEffect(() => {
    inscriboorService.getRecommendedFeeRate(setFeeRate);

    // Set username and address from local storage
    const selectedUsername = JSON.parse(localStorage.getItem('selectedUsername'));
    if (selectedUsername && selectedUsername.name_escaped) {
      setUsername(selectedUsername.name_escaped);
    }

    const storedAddress = localStorage.getItem('ordinalAddress');
    if (storedAddress) {
      console.log(storedAddress);
      setAddress(storedAddress);
    }

    // Get the hash part of the URL
    const hash = window.location.hash;
    // Extract the query string from the hash
    const queryString = hash.includes('?') ? hash.split('?')[1] : '';
    const urlParams = new URLSearchParams(queryString);
    const query = urlParams.get('replytoID');
    if (query) {
      setReplytoID(query);
      console.log('Reply to ID:',query);

      // Fetch the post being replied to using getSinglePost
      const fetchReplyPost = async () => {
        try {
          const response = await services.getSinglePost(query);
          setReplyPost(response.documents[0]);
          console.log(response);
          console.log('Reply Post:', response.documents);
        } catch (error) {
          console.error('Error fetching reply post:', error);
        }
      };

      fetchReplyPost();
    }
    }, []); // Remove postContent and feeRate from dependencies
    
    // Add a separate useEffect for submit button validation
    useEffect(() => {
      const selectedUsername = JSON.parse(localStorage.getItem('selectedUsername'));
      if (!selectedUsername || !postContent || !feeRate) {
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
      }
    }, [postContent, feeRate]);

  const handleAddressChange = (e) => {
    const address = e.target.value;
    setAddress(address);
    if (inscriboorService.isValidTaprootAddress(address)) {
      e.target.style.backgroundColor = '#d4edda'; // light green
      setSubmitDisabled(false);
    } else {
      e.target.style.backgroundColor = '#f8d7da'; // light red
      setSubmitDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Retrieve selectedUsername from local storage
      const selectedUsername = JSON.parse(localStorage.getItem('selectedUsername'));
      if (!selectedUsername || !selectedUsername.inscription_id) {
        console.error("Inscription ID not found in local storage.");
        setStatus('Please try reconnecting your wallet.')
        return;
      }
      setUsername(selectedUsername.name_escaped);

      // Get the inscriptionID
      const inscriptionID = selectedUsername.inscription_id;
      console.log('Parent Inscription ID:',inscriptionID);
      try {
        // Call the API to get parent UTXO info
        const parentUtxoInfo = await services.getParentUtxoInfo(inscriptionID);
        console.log('Parent Inscription UTXO Info:',parentUtxoInfo);
        if (!parentUtxoInfo || parentUtxoInfo.length === 0) {
          console.error('Parent Not Found in wallet');
          setStatus(`The name you are posting from is not in your wallet. You may already have a post in progress or it was moved out of your wallet.`);
          return;
        }
        const parentInsID = inscriptionID;
        const parentOutVal = parentUtxoInfo.utxo.satoshi;

        const postMetadata = {
          username,
          op: "reply",
          ordinal: replytoID
        };
        console.log('Ord Metadata:',postMetadata);
        const cborPostMetadata = encode(postMetadata);
        const transmitCbor = Array.from(new Uint8Array(cborPostMetadata))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');

        const payload = {
          address,
          feeRate,
          data: postContent,
          mime: "text/plain",
          padding: "550",
          metaprotocol: "bitter",
          metadata: transmitCbor,
          type: "single",
          collection: "bitter",
          parentInsID,
          parentOutVal,
        };
        console.log('Inscribe Payload:',payload);

        // Call inscribeChild and get the response
        const response = await inscriboorService.inscribeChild(payload);
        console.log('Inscribe Response:',response);
        const { sats, fundingAddress, parentAddress } = response.data;

        // Get available payment UTXOs
        const paymentAddress = localStorage.getItem('paymentAddress');
        const paymentUtxos = await services.getPaymentUtxoInfo(paymentAddress, sats);
        if (!paymentUtxos || paymentUtxos.length === 0) {
          console.error('No valid payment UTXO found.');
          setStatus(`No valid payment UTXO found. Please ensure your wallet has sufficient funds. You need at least ${sats} sats in 1 UTXO`);
          return;
        }
        const postPaymentUTXO = paymentUtxos[0];
        console.log('Payment UTXO:',postPaymentUTXO);
        // Call inscribePost
        const inscribeSuccess =await inscribePost({
          selectedOrdUtxo: parentUtxoInfo.utxo,
          selectedPaymentUtxo: postPaymentUTXO,
          fundingAddress,
          userDefinedAmount: sats,
          parentAddress,
        });
        if (inscribeSuccess) {
          // Call the new /addunconfirmedpost endpoint
          const unconfirmedPostPayload = {
            name: username,
            content: postContent,
            address: address,
            fundingAddress: fundingAddress,
            replytoID: replytoID
          };

          const unconfirmedResponse = await services.addUnconfirmedPost(unconfirmedPostPayload);
          console.log("Unconfirmed post added:", unconfirmedResponse);
          /*
          if (unconfirmedResponse.status === 201) {
            setStatus(`Post added successfully.`);
          } else if (unconfirmedResponse.status === 202) {
            setStatus(`Your post is on-chain but may not appear as unconfirmed. Please wait for the next block to clear`);
          } else {
            setStatus(`Unexpected response: ${unconfirmedResponse.status}`);
          }
          */
        }
        console.log('Parent UTXO:',parentUtxoInfo.utxo);
        console.log('Payment UTXO:',postPaymentUTXO);
      } catch (error) {
        console.error("Error fetching parent UTXO info or inscribing:", error);
      }
    } finally {
      console.log('lze')
    }
  };

  

const inscribePost = async ({ selectedOrdUtxo, selectedPaymentUtxo, fundingAddress, userDefinedAmount, parentAddress }) => {
    try {
        const userAddresses = {
            paymentKey: localStorage.getItem('paymentPubKey'),
            ordinalKey: localStorage.getItem('ordinalPubKey'),
            ordinal: localStorage.getItem('ordinalAddress'),
            payment: localStorage.getItem('paymentAddress'),
        };

        const psbtB64 = await createPSBT(
            selectedOrdUtxo,
            selectedPaymentUtxo,
            userAddresses.ordinalKey,
            userAddresses.paymentKey,
            userAddresses.ordinal,
            userAddresses.payment,
            fundingAddress,
            userDefinedAmount,
            parentAddress
        );

        console.log('Generated PSBT:', psbtB64);

        // Decode PSBT from Base64 for bitcoinjs-lib
        const psbt = Psbt.fromBase64(psbtB64);
        const defaultProvider = localStorage.getItem('sats-connect_defaultProvider');
        if (defaultProvider === 'unisat') {
            // Unisat-specific signing with auto-finalization
            const psbtHex = psbt.toHex(); // Convert to hex for Unisat
            try {
                const unisatResponse = await window.unisat.signPsbt(psbtHex, {
                    autoFinalized: true, // Set autoFinalized to true for Unisat
                    toSignInputs: [
                        {
                            index: 0,
                            address: userAddresses.ordinal,
                        },
                        {
                            index: 1,
                            address: userAddresses.payment,
                        }
                    ],
                    sighashTypes: [btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY | 131],
                });

                if (unisatResponse) {
                    console.log('Unisat signed and finalized PSBT:', unisatResponse);
                    
                    // Load signed PSBT back into bitcoinjs-lib for extraction
                    const signedPsbt = Psbt.fromHex(unisatResponse);
                    const txHex = signedPsbt.extractTransaction().toHex();

                    var posttxid = await broadcastTransactions([txHex]);
                    console.log('Transaction broadcasted successfully:', txHex);
                    setStatus(
                      <>
                        Replied successfully! - <Link to={`https://www.mempool.space/tx/${posttxid}`} target="_blank">Mempool TxID</Link>
                      </>
                    )
                    setPostContent('');
                    try {
                      await services.addName({
                        name: username,
                        address: address
                      });
                    } catch (error) {
                      console.error("Error adding name:", error);
                    }
                    return true;
                } else {
                    console.error('Failed to sign and finalize PSBT with Unisat.');
                    setStatus(`User Cancelled the request`)
                    return false;
                }
            } catch (unisatError) {
                console.error('Error during Unisat transaction signing:', unisatError);
                setStatus(`Whoops, something went wrong, please try again.`)
                return false;
            }
        } else {
            // Existing logic for other wallets
            try {
              const response = await Wallet.request('signPsbt', {
                psbt: psbtB64,
                allowedSignHash: btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY | 131,
                signInputs: {
                  [userAddresses.ordinal]: [0],
                  [userAddresses.payment]: [1],
                },
              });
      
              if (response.status === "success") {
                console.log('Transaction signed successfully:', response);
                const signedPsbt = base64.decode(response.result.psbt);
                const tx = btc.Transaction.fromPSBT(signedPsbt);
                tx.finalize();
                const txHex = tx.hex;
      
                var posttxid = await broadcastTransactions([txHex]);
                console.log('Transaction broadcasted successfully:', txHex);
                setStatus(
                  <>
                    Replied successfully! - <Link to={`https://www.mempool.space/tx/${posttxid}`} target="_blank">Mempool TxID</Link>
                  </>
                )
                setPostContent('');
                try {
                  await services.addName({
                    name: username,
                    address: address
                  });
                } catch (error) {
                  console.error("Error adding name:", error);
                }
                return true;
              } else {
                if (response.error.code === RpcErrorCode.USER_REJECTION) {
                  alert("Transaction signing canceled.");
                  setStatus(`User cancelled the request`)
                  return false;
                } else {
                  console.error('Error signing transaction:', response.error);
                  setStatus(`User cancelled the request`)
                  return false;
                }
              }
            } catch (err) {
              console.error('Error during transaction signing:', err);
              setStatus(`Whoops, something went wrong, please try again.`)
              return false;
            }
        }
    } catch (error) {
        console.error('Error creating or signing PSBT:', error);
        alert("An error occurred while creating or signing the PSBT.");
        return false;
    }
};



  const createPSBT = async (ordOutput, paymentOutput, ordinalPubKey, paymentPubKey, ordinalsAddress, changeAddress, fundingAddress, userDefinedAmount, parentAddress) => {
    console.log('Parent Address:', parentAddress);
    console.log('Funding Address:', fundingAddress);
    console.log('Change Address:', changeAddress);
    const internalPubKey = hex.decode(ordinalPubKey);
    const paymentPubKeyDecoded = hex.decode(paymentPubKey);
  
    const p2tr = btc.p2tr(internalPubKey, undefined, networkConfig); // Taproot for ordinal output
    //const p2wpkh = btc.p2wpkh(paymentPubKeyDecoded, networkConfig); // Wrapped segwit for payment output
    //const p2sh = btc.p2sh(p2wpkh, networkConfig); // Wrap P2WPKH in P2SH
    
  
    let tx = new btc.Transaction(networkConfig);
  
    // Add ord output as input (Taproot)
    tx.addInput({
      txid: ordOutput.txid,
      index: ordOutput.vout,
      witnessUtxo: {
        script: p2tr.script,
        amount: BigInt(ordOutput.satoshi),
      },
      tapInternalKey: internalPubKey,
      sighashTypes: btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY,
    });
  
    // Add payment output as input depending on wallet provider (P2SH-P2WPKH or P2TR)
    const defaultProvider = localStorage.getItem('sats-connect_defaultProvider');

    if (defaultProvider === 'unisat') {
      // Add payment output as input using p2tr
      tx.addInput({
        txid: paymentOutput.txid,
        index: paymentOutput.vout,
        witnessUtxo: {
          script: p2tr.script, // Use p2tr script
          amount: BigInt(paymentOutput.satoshi), // Value of the payment UTXO
        },
        tapInternalKey: internalPubKey,
        sighashTypes: btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY,
      });
    } else {
      const p2wpkh = btc.p2wpkh(paymentPubKeyDecoded, networkConfig);
      // Add payment output as input (P2SH-P2WPKH)
      tx.addInput({
        txid: paymentOutput.txid,
        index: paymentOutput.vout,
        witnessUtxo: {
          script: p2wpkh.script,
          amount: BigInt(paymentOutput.satoshi), // Value of the payment UTXO
        },
        //redeemScript: p2sh.redeemScript, // Add redeemScript for P2SH-P2WPKH
      });
    }
  
    // Outputs: send ord, userDefinedAmount, and calculate change
    const totalSats = ordOutput.satoshi + paymentOutput.satoshi;
    const ordOutputSats = ordOutput.satoshi;
    const changeSats = totalSats - userDefinedAmount - ordOutputSats - 800;
  
    console.log(totalSats);
    console.log(ordOutputSats);
    console.log(changeSats);
    // Ordinals output stays the same
    tx.addOutputAddress(parentAddress, BigInt(ordOutputSats), networkConfig);
    
    // Send the user-defined amount to the funding address
    tx.addOutputAddress(fundingAddress, BigInt(userDefinedAmount), networkConfig);
    
    // Send change to payment address
    tx.addOutputAddress(changeAddress, BigInt(changeSats), networkConfig);
  
    // Generate and return the base64 encoded PSBT
    const psbt = tx.toPSBT(0);
    const psbtB64 = base64.encode(psbt);
    return psbtB64;
  };

  
  const broadcastTransactions = async (txHexList) => {
    for (const txHex of txHexList) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://mempool.space/api/tx',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: txHex
      };
  
      try {
        const response = await axios.request(config);
        console.log('Broadcast Response:', response);
        const txid = response.data;
        console.log('Transaction ID:', txid);
        return txid; // Return the raw txid without JSON.stringify
      } catch (error) {
        console.error(`Error broadcasting transaction: ${error.message}`);
        throw error; // Propagate the error up
      }
    }
  };

  const handleCheckOrderStatus = () => {
    if (orderID) {
      inscriboorService.checkOrderStatus(orderID, (response) => {
        setResponseStatus(response.status);
        if (response.inscriptionID) {
          setInscriptionID(response.inscriptionID);
        }
      });
    }
  };

  return (
    <Wrapper>
      <>
        {status &&
          <NotificationBar message={status} />
        }
      <div id="postContainer">
        {replyPost && (
          <Post post={replyPost} redirectURL={`/post?id=${replyPost.inscriptionID}`} />
        )}
      </div>
      <form id="addPost" onSubmit={handleSubmit}>
        <label htmlFor="post-content">Reply Content</label><br />
        <textarea
          id="post-content"
          placeholder="💬 bitter"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          required
        /><br />
        {/*
        <label htmlFor="username">Post As</label><br />
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="user.bitter"
          required
        /><br />
        <label htmlFor="addressInput">Ordinal Receiving Address</label><br />
        <input
          type="text"
          id="addressInput"
          name="address"
          value={address}
          onChange={handleAddressChange}
          placeholder="address"
          required
        /><br />
        */}
        <label htmlFor="feeInput">Fee Rate (sat/vB)</label><br />
        <input
          type="number"
          id="feeInput"
          value={feeRate}
          onChange={(e) => setFeeRate(e.target.value)}
          placeholder="Fee"
        /><br />
        <div className="submit">
          <button 
            type="submit" 
            id="submitButton" 
            disabled={submitDisabled}
          >
            Submit
          </button>
        </div>
      </form>
      </>
      <div id="wrapper">
        <div id="info">{responseStatus}</div>
        <div id="summary">{inscriptionID && <a href={`https://ordinals.com/inscription/${inscriptionID}`} target="_blank" rel="noopener noreferrer">View Inscription</a>}</div>
        <div id="response">{responseStatus}</div>
        <p id="countdown">{countdown}</p>
        <button
          type="button"
          id="checkOrderStatus"
          onClick={handleCheckOrderStatus}
          className={orderID ? '' : 'hidden'}
        >
          Check Order Status
        </button>
      </div>
    </Wrapper>
  );
};

export default Reply;