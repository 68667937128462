import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// Configure NProgress once, outside the component
NProgress.configure({ 
  showSpinner: false,
  trickleSpeed: 100,
  minimum: 0.3
})

export function TopLoader() {
  const location = useLocation()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(false)
    
    const handleRenderReady = () => {
      setIsReady(true)
    }

    document.addEventListener('renderReady', handleRenderReady)
    
    // Force NProgress to reset
    NProgress.done()
    NProgress.start()

    return () => {
      document.removeEventListener('renderReady', handleRenderReady)
      NProgress.done()
    }
  }, [location.pathname, location.search]) // Track both path and search params

  return (
    <style>
      {`
        .App > div:not(#nprogress) {
          visibility: ${isReady ? 'visible' : 'hidden'};
        }
        #nprogress {
          pointer-events: none;
        }
        #nprogress .bar {
          background: orange;
          position: fixed;
          z-index: 9999;
          top: 0;
          left: 0;
          width: 100%;
          height: 3px;
        }
        #nprogress .peg {
          display: none;
        }
      `}
    </style>
  )
} 