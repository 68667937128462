import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StatusIcon from '../components/StatusIcon.js';

function Post({ post, redirectURL }) {
  const navigate = useNavigate();
  const formattedUsername = encodeURIComponent(post.name);

  const handleReplyClick = (e, replytoID) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/reply?replytoID=${replytoID}`);
  };

  const handlePostClick = (e) => {
    if (redirectURL) {
      if (e.ctrlKey || e.metaKey || e.button === 1) {
        window.open(redirectURL, '_blank').focus();
      } else {
        navigate(redirectURL);
      }
    }
  };

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="post" onClick={handlePostClick}>
      <div className="post-author">
        <Link 
          to={`/user?user=${formattedUsername}`} 
          className="author-name"
          onClick={handleLinkClick}
        >
          {post.name}
        </Link>
      </div>

      <div className="post-content">
        <div className="content-text">
          {post.content.split(/(https?:\/\/\S+)/g).map((part, index) =>
            part.match(/https?:\/\/\S+/) ? (
              <Link key={index} to={part} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                {part}
              </Link>
            ) : (
              part
            )
          )}
        </div>
      </div>
      
      <div className="post-meta">
        <div className="post-time">
          {post.status === 'confirmed' && (
            new Date(post.timestamp * 1000).toLocaleString()
          )}
          <StatusIcon 
            status={post.status} 
            link={post.inscriptionID ? 
              post.status === 'confirmed' 
                ? `https://ordinals.com/inscription/${post.inscriptionID}`
                : `https://mempool.space/tx/${post.txid}`
              : null
            } 
          />
        </div>
        
        <button 
          className="reply-button" 
          onClick={(e) => handleReplyClick(e, post.inscriptionID)}
        >
          reply
        </button>
      </div>
    </div>
  );
}

export default Post;
