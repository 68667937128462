import React, { useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper.js'
import { useLocation, Link } from 'react-router-dom';
import { services } from '../services/service.js';
import Post from './home-post.js';
import NotificationBar from '../components/NotificationBar.js';

function User() {
  const [posts, setPosts] = useState(null)
  const [userExist, setUserExist] = useState(null)
  const [userName, setUserName] = useState(null)
  const [status, setStatus] = useState(null)
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('user').toLowerCase();

  useEffect(() => {
    // Set initial states
    setUserName(query)
    setUserExist(null)
    setPosts(null)
    setStatus('')

    const fetchUserData = async () => {
      try {
        const userData = await services.searchUsers(query)
        
        // Check if response is an error or has error status
        if (userData?.response?.status === 404 || userData?.code === 'ERR_BAD_REQUEST') {
          setUserExist(false)
          return // Exit early if user not found
        }

        setUserExist(true)
        setUserName(userData.name)
        
        // Only fetch posts if user exists
        const postsData = await services.getUserPosts(userData.name)
        if (postsData && postsData.documents) {
          setPosts(postsData.documents)
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        setUserExist(false)
        // Keep the original query as username
      }
    }

    fetchUserData()
  }, [query])

  const checkNameAvailability = async () => {
    try {
      const result = await services.checkName(query)
      if (result && result.data && result.data.name) {
        // Name exists in Geniidata
        setStatus(`${query} is already registered`)
      } else {
        // Name is available
        setStatus(
          <>
            {query} is available - <Link to={`/register?name=${encodeURIComponent(query)}`}>register now!</Link>
          </>
        )
      }
    } catch (error) {
      console.error('Error checking name availability:', error)
      setStatus('Error checking name availability')
    }
  }

  return (
    <Wrapper>
      {status && <NotificationBar message={status} />}
      <div id="userPostContainer">
        {userName && <h1>{userName}</h1>}
        {!userExist ? (
          <div className="name-check-container">
            <button 
              onClick={checkNameAvailability}
              className="check-name-btn"
            >
              Check if this name is available
            </button>
          </div>
        ) : !posts || posts.length === 0 ? (
          <p className='no-posts-message'>This user has not yet posted</p>
        ) : (
          posts.map((post, index) => (
            <Post key={index} post={post} redirectURL={`/post?id=${post.inscriptionID}`} />
          ))
        )}
        
      </div>
    </Wrapper>
  )
}

export default User