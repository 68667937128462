import React, { useState } from 'react'

function PopupData({ open, handleClose, data }) {
  const [tabIndex, setTabIndex] = useState('bitter');

  const handleTabChange = (namespace) => {
    setTabIndex(namespace);
  };

  const handleItemClick = (item) => {
    localStorage.setItem('selectedUsername', JSON.stringify(item));
    handleClose()
  };

  const renderList = (namespace) => {
    if (namespace === 'all') {
      return data
        .filter(item => item.namespace !== 'bitter')
        .map(item => (
          <div
            key={item.name_escaped}
            className="list-item"
            onClick={() => handleItemClick(item)}
          >
            {item.name_escaped}
          </div>
        ));
    } else {
      return data
        .filter(item => item.namespace === namespace)
        .map(item => (
          <div
            key={item.name_escaped}
            className="list-item"
            onClick={() => handleItemClick(item)}
          >
            {item.name_escaped}
          </div>
        ));
    }
  };

  if (!open) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2 className="modal-title">Select a Name</h2>
        <button className="close-button" onClick={handleClose}>×</button>
        <div className="tabs">
          <button onClick={() => handleTabChange('bitter')} className={tabIndex === 'bitter' ? 'active' : ''}>bitter</button>
          <button onClick={() => handleTabChange('sns')} className={tabIndex === 'sns' ? 'active' : ''}>sns</button>
        </div>
        <div className="tab-content">
          {tabIndex === 'bitter' && renderList('bitter')}
          {tabIndex === 'sns' && renderList('all')}
        </div>
      </div>
    </div>
  );
};

export default PopupData