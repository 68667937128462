import React, { useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper.js';
import { services } from '../services/service.js';
import '../css/leaderboard.css'

const Leaderboard = () => {
  const [postsData, setPostsData] = useState([]);
  const [repliesData, setRepliesData] = useState([]);
  const [namesData, setNamesData] = useState([]);

  useEffect(() => {
    // Fetch and set posts and replies data
    services.getPostsAndRepliesPerName().then((data) => {
      const posts = data.filter(item => item.numPosts > 0).sort((a, b) => b.numPosts - a.numPosts);
      const replies = data.filter(item => item.numReplies > 0).sort((a, b) => b.numReplies - a.numReplies);
      setPostsData(posts);
      setRepliesData(replies);
    });

    // Fetch and set names data
    services.getNamesPerAddress().then((data) => {
      const names = data.filter(item => item.numNames > 0).sort((a, b) => b.numNames - a.numNames);
      setNamesData(names);
    });
  }, []);

  const renderTableRows = (data, type) => {
    return data.map((item, index) => {
      const username = item._id.replace('.bitter', '');  // Remove '.bitter' from the username
      const truncatedId = item._id.slice(0, 5) + '...' + item._id.slice(-5);

      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {type === 'names' ? (
              <a href={`https://ordinalswallet.com/address/${item._id}`} target="_blank" rel="noopener noreferrer">{truncatedId}</a>
            ) : (
              <a href={`/user?user=${encodeURIComponent(username)}`}>{username}</a>
            )}
          </td>
          <td>{type === 'names' ? item.numNames : type === 'posts' ? item.numPosts : item.numReplies}</td>
        </tr>
      );
    });
  };

  return (
    <Wrapper>
      <h2>Posts</h2>
      <table id="postsLeaderboard">
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th># of Posts</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(postsData, 'posts')}
        </tbody>
      </table>

      <h2>Replies</h2>
      <table id="repliesLeaderboard">
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th># of Replies</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(repliesData, 'replies')}
        </tbody>
      </table>

      <h2># of Names</h2>
      <table id="namesLeaderboard">
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th># of Names</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(namesData, 'names')}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Leaderboard;
