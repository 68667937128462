import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { services } from '../services/service.js'

function Header() {
  const searchType = ['users', 'posts']
  const [searchValue, setSearchValue] = useState(null)
  const [searchSelect, setSearchSelect] = useState(searchType[0])
  const navigate = useNavigate()

  const handleSearch = async (e) => {
    e.preventDefault() // Prevent default form submission
    if (searchValue && (searchSelect === 'users' ? e.target.search.validity.valid : true)) {
      if (searchSelect === 'users') {
        try {
          const userData = await services.searchUsers(searchValue)
          // The server returns { name, address } directly if found
          if (userData && userData.name) {
            navigate(`/user?user=${encodeURIComponent(searchValue)}`)
            return
          }
          // If no user found, redirect to register
          navigate(`/register?name=${encodeURIComponent(searchValue)}`)
        } catch (error) {
          console.error('Error searching for user:', error)
          navigate(`/register?name=${encodeURIComponent(searchValue)}`)
        }
      } else if (searchSelect === 'posts') {
        // Try a text content search
        const contentData = await services.searchPosts(searchValue) // use encodeURIComponent to encode the query in the URL
        // If content was found, redirect to a search results page
        if (contentData && contentData.documents && contentData.documents.length > 0) {
          // Redirect to a search results page (you'll need to create this)
          navigate(`/search?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        } else {
          // If no content was found, redirect to the add post page with the query parameter
          navigate(`/add?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        }
      }
    }
  }

  return (
    <>
      <header className="header">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <h1>😖 bitter.lol</h1>
        </Link>
        
        <form className="search-container" onSubmit={handleSearch}>
          <input 
            type="text" 
            id="search" 
            name="search"
            required
            placeholder={searchSelect === 'users' ? "enter name.namespace" : "search"} 
            onChange={(e) => setSearchValue(e.target.value)}
            pattern={searchSelect === 'users' ? "^[^.]+\\.[^.]+$" : ".*"}
            title="Please enter a valid format: name.namespace"
          />
          <select id="search-type" onChange={(e) => setSearchSelect(e.target.value)}>
            <option value="users">users</option>
            <option value="posts">posts</option>
          </select>
          <button type="submit" id="search-btn">search</button>
        </form>
      </header>
    </>
  )
}

export default Header