import React from 'react';

function ThemeToggle({ onClose }) {
  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    if (onClose) onClose();
  };

  const isDark = document.documentElement.getAttribute('data-theme') === 'dark';

  return (
    <button className="fab-item" onClick={toggleTheme}>
      <span className="fab-icon">{isDark ? '☀️' : '🌙'}</span>
      <span className="fab-label">{isDark ? 'Light Mode' : 'Dark Mode'}</span>
    </button>
  );
}

export default ThemeToggle; 