import React from 'react'
import Wrapper from '../components/Wrapper.js'

function About() {
  return (
    <Wrapper>
      <div className="about">
        <h1>About bitter.lol</h1>
        <p>Welcome to bitter.lol, a fun, experimental social network built on Bitcoin and powered by ordinal theory. Leveraging the .bitter protocol, users inscribe their bitters on-chain using a .bitter or any SNS name, making every post a permanent part of the Bitcoin ledger.</p>
        <p><strong>Please note:</strong> This platform is experimental and intended for exploration and fun. The inscriptions carry no financial value.</p>
        
        <h2>What's New in bitter.lol v2?</h2>
        <p>The transition from v1 to v2 marks a significant upgrade in how bitter.lol works. Key changes include:</p>
        <ul>
          <li><strong>Parent-Child Provenance:</strong> Posts and replies are now tied to the creator's on-chain identity using parent-child relationships in the ord protocol. This ensures that only the owner of a username can post under that name, bringing integrity and accountability to the platform.</li>
          <li><strong>Direct Wallet Integration:</strong> .bitter now integrates seamlessly with Unisat and Xverse BTC wallets, simplifying the process of creating and managing inscriptions for posts and usernames.</li>
          <li><strong>Simplified Protocol:</strong> The v2 .bitter protocol spec eliminates redundant fields and streamlines the structure of posts and replies. Content is now stored directly in the Ord content field, while metadata is CBOR encoded and stored in the Ord metadata field. All posts and replies include the .bitter Ord metaprotocol field as well.</li>
          <li><strong>Community-Driven Evolution:</strong> The v2 specification was developed as an evolution of the original v1 spec, filling in gaps and extending functionality where needed. This upgrade ensures a more robust and user-centric platform.</li>
        </ul>
        
        <h2>How does it work?</h2>
        <p>bitter.lol operates using the ord protocol and a straightforward inscription-based system. Here’s how to get started:</p>
        <ul>
          <li><strong>Claim Your Username:</strong> Create and inscribe a username in the format 'username.bitter'. This inscription serves as your digital identity on the platform. Only the first inscription of a username is considered valid.</li>
          <li><strong>Create Posts:</strong> Posts are inscriptions tied to your username using a parent-child structure. </li>
          <li><strong>Create Replies:</strong> Replies are also tied to your username. </li>
        </ul>

        <h2>Important Information</h2>
        <p>Here are a few things to keep in mind while using .bitter:</p>
        <ul>
          <li>Usernames are first-come, first-serve. Ownership is verified through the ord protocol.</li>
          <li>Posts and replies are tied to the creator's username via parent-child provenance, ensuring authenticity.</li>
          <li>bitter.lol is just a client of the .bitter protocol. New specifications of the protocol can be found below.</li>
          <li>This platform is not designed for financial speculation or value generation.</li>
        </ul>
        
        <h2>Technical Overview</h2>
        <p>.bitter is more than just a platform—it's a protocol. bitter.lol is one implementation of the .bitter protocol, meaning anyone can create their own client as long as it adheres to the v2 specification. This ensures openness and decentralization, core principles of Bitcoin.</p>

        <h3>Deploying a .bitter Handle</h3>
        <p>To create a username, inscribe it in the format <code>username.bitter</code>. Only the inscription with the lowest Ordinal ID is considered the valid username for a given handle. The process for deploying a .bitter handle remains unchanged in v2.</p>

        <h3>Operations</h3>
        <p>All operations must adhere to the v2 specification, ensuring traceability and consistency across clients. JSON objects are required for all functions except for claiming usernames, which is a direct inscription operation.</p>

        <h4>Create a Post</h4>
        <pre>
{`{
  "username": "SatoshiNakamoto.bitter",
  "op": "post"
}`}
        </pre>
        <p><strong>Details:</strong></p>
        <ul>
          <li><code>username</code>: The .bitter or SNS name of the user posting. Must be valid and owned by the inscriber.</li>
          <li><code>op</code>: The operation type. For posts, this is <code>"post"</code>.</li>
        </ul>
        <p><strong>Important:</strong> In v2, post content is directly inscribed in the <code>content</code> field of the Ordinal inscription. Metadata such as <code>username</code> and <code>op</code> are stored in the <code>metadata</code> field, simplifying the JSON structure.</p>

        <h4>Create a Reply</h4>
        <pre>
{`{
  "username": "SatoshiNakamoto.bitter",
  "op": "reply",
  "ordinal": "34b174f9fe3027d4ab8f2bb7f9e320259e596306d751cc0c09e7b4906f4d1ddb"
}`}
        </pre>
        <p><strong>Details:</strong></p>
        <ul>
          <li><code>username</code>: The .bitter or SNS name of the user replying. Must be valid and owned by the inscriber.</li>
          <li><code>op</code>: The operation type. For replies, this is <code>"reply"</code>.</li>
          <li><code>ordinal</code>: The Ordinal ID of the post being replied to.</li>
        </ul>
        <p>Reply content, like posts, is directly inscribed in the <code>content</code> field of the Ordinal inscription, while other metadata is stored in the <code>metadata</code> field.</p>

        <h3>Protocol Rules</h3>
        <ul>
          <li>Posts and replies are only valid if they are inscribed as children of a .bitter or SNS name. This ensures all interactions on the protocol are tied to a verifiable identity.</li>
          <li>bitter.lol supports all SNS names, providing flexibility for users to register and use any valid name.</li>
          <li>The JSON structure for v2 eliminates the <code>p</code> and <code>content</code> fields, moving metadata to the <code>metadata</code> field of inscriptions. This change simplifies processing and improves compatibility.</li>
        </ul>

        <h3>Community Contributions</h3>
        <p>The .bitter protocol is open to community input and improvement. Developers are encouraged to create their own clients, propose enhancements, and explore new use cases for on-chain social media.</p>
      </div>
    </Wrapper>
  )
}

export default About
