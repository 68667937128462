import React, { useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper.js';
import Post from './home-post.js';
import { services } from '../services/service.js';

function Home() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialPosts = async () => {
      const data = await services.getPosts(1);
      setPosts(data.documents);
      console.log(`Initially loaded ${data.documents.length} posts`);
    };

    fetchInitialPosts();
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY || window.pageYOffset;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      if (windowHeight + scrollPosition >= documentHeight - 100 && !loading) {
        setLoading(true);
        const nextPage = currentPage + 1;
        const newData = await services.getPosts(nextPage);
        setPosts((prevPosts) => [...prevPosts, ...newData.documents]);
        console.log(`Scroll triggered, loading ${newData.documents.length} new posts`);
        setCurrentPage(nextPage);
        setLoading(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPage, loading]);

  return (
    <Wrapper>
      <div id="postContainer">
        {posts.map((post, index) => (
          <Post key={index} post={post} redirectURL={`/post?id=${post.inscriptionID}`} />
        ))}
      </div>
    </Wrapper>
  );
}

export default Home;
