import React from 'react'
import Header from './Header.js'
import Footer from './Footer.js'
import FloatingActionButton from './fab.js';


function Wrapper({children}) {
  return (
    <div className='main'>
      <Header />
      <div className='page-wrapper'>{children}</div>
      <Footer />
      <FloatingActionButton />
    </div>
  )
}

export default Wrapper