const StatusIcon = ({ status, link }) => {
    const getStatusStyle = () => {
      switch (status) {
        case 'confirmed':
          return { color: '#4CAF50' }; // Green
        case 'unconfirmed':
          return { color: '#FFC107' }; // Yellow/Orange
        default:
          return { color: '#9E9E9E' }; // Grey
      }
    };
  
    const getStatusText = () => {
      switch (status) {
        case 'confirmed':
          return 'On-Chain';
        case 'unconfirmed':
          return 'Pending Confirmation';
        default:
          return 'Status unknown';
      }
    };
  
    return (
      <div 
        className="StatusIcon" 
        title={getStatusText()}
        onClick={(e) => {
          e.stopPropagation();
          link && window.open(link, '_blank');
        }}
      >
        {status === 'confirmed' ? '🟠' : '⚪'}
      </div>
    );
  };

  export default StatusIcon;