import React, { useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper.js'
import { useLocation } from 'react-router-dom';
import { services } from '../services/service.js';
import Post from './home-post.js';

function SinglePost() {
  const [post, setPost] = useState(null)
  const [replies, setReplies] = useState(null)
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    services.getSinglePost(query).then((response) => {
      setPost(response.documents[0])
    });
  }, [query])

  useEffect(() => {
    services.getSinglePostReplies(query).then((response) => {
      setReplies(response.documents[0])
    });
  }, [query])

  return (
    <Wrapper>
      <div id="singlePostContainer">
        {post && <Post post={post} />}
      </div>
      <div id="repliesContainer">
        {replies && <Post post={replies} />}
      </div>
    </Wrapper>
  )
}

export default SinglePost